import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { MenuItem } from '@mui/material';
import List from './List';
import {
  FilterBox,
  PageHeader,
  PageAction,
  PrimaryButton,
  SelectFilterField,
  PrimaryPage,
  FormButton
} from '@/Theme';
import { OrderService } from '@/Services';
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";
import _ from 'underscore';
import "react-datepicker/dist/react-datepicker.css";

const XLSX_ZAHL_PAYLOAD = require("xlsx/dist/xlsx.zahl");

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState({ search: "", status: ['New', 'Pending'] });
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(['New', 'Pending']);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('created');
  const [direction, setDirection] = useState('desc');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //For editing
  const [selectedItem, setSelectedItem] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = {
    where: {},
    size: size,
    page: page,
    sort: sort,
    order: direction,
  };
  const fetch = async () => {
    setLoading(true);
    filters.where = filter;
    let response = await OrderService.search(filters);
    if (response && response.data) {
      const objects = response.data.objects;
      setListData(objects);
      setCount(response.data.count);
    }
    setLoading(false);
  };
  useEffect(() => {
    let kn = searchParams.get("kn");
    if (kn) {
      setSearch(kn);
    }
  }, []);
  useEffect(() => {
    console.log('### fetch test types', search, size, page, sort, direction);
    filter.where = { freeText: search };
    fetch();
  }, [filter, size, page, sort, direction]);

  const handleUpdateStatus = async (row) => {
    let response = await OrderService.updateStatus(row);
    fetch();
  };
  const onSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const onSearchPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let updatedFilter = {
        ...filter,
        ...{ search: search },
        ...{ status: status },
      }
      setFilter(updatedFilter);
    }
  };
  const handleApplyFilter = () => {
    let updatedFilter = {
      ...filter,
      ...{ search: search },
      ...{ status: status },
    }
    setFilter(updatedFilter);
  }
  const onStatusSelect = (event) => {
    const { target: { value } } = event;
    setStatus(typeof value === 'string' ? value.split(',') : value,);
  }
  const handleNew = () => {
    let link = `/kit/order/new`;
    navigate(link);
  }
  const onStartChange = (date) => {
    let updatedFilter = { ...filter, ...{ startDate: date } }
    setFilter(updatedFilter);
    setStartDate(date);
  }
  const onEndChange = (date) => {
    let updatedFilter = { ...filter, ...{ endDate: date } }
    setFilter(updatedFilter);
    setEndDate(date);
  }
  const exportCSV = async () => {
    let data = [['Customer','Organization','Line Items','Status','Last Updated']]

    let csvFilters = {
      where: filter,
      size: 99999,
      page: 1,
      sort: sort,
      order: direction
    };

    let response = await OrderService.search(csvFilters);
    _.each(response.data.objects, order => {
      let row = []
      row.push(order.customer.lastName + ', ' + order.customer.firstName)
      row.push(order.organization ? order.organization.name : '')
      row.push(order.lineItems.map(item => item.kit.name).join(', '))
      row.push(order.status)
      row.push(DateTime.fromISO(order.updated).toLocaleString(DateTime.DATETIME_SHORT))
      data.push(row);
    });

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "orders.xlsx", {numbers: XLSX_ZAHL_PAYLOAD, compression: true});
  }
  return (
    <PrimaryPage title='Kit Orders'>
      <PageHeader>
        <PageAction>
          <FilterBox value={search} onChange={onSearchChange} onPress={onSearchPressed}
            extend={
              <>
                <SelectFilterField
                  labelId="filter-status-label"
                  id="filter-status-id"
                  value={status}
                  multiple
                  onChange={onStatusSelect}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </SelectFilterField>
                <FormButton compact primary onClick={handleApplyFilter}>Filter</FormButton>
              </>
          } />
          <div style={{marginLeft:20}}>
            <DatePicker placeholderText='Start Date' selected={startDate} onChange={onStartChange} />
            <DatePicker placeholderText='End Date' selected={endDate} onChange={onEndChange} />
          </div>
        </PageAction>
        <PrimaryButton onClick={handleNew} size="small">New Kit Order</PrimaryButton>
        <PrimaryButton onClick={exportCSV} size='small' style={{backgroundColor:'#6458cf'}}>Export to excel</PrimaryButton>
      </PageHeader>
      <List
        listData={listData}
        handleUpdateStatus={handleUpdateStatus}
        loading={loading}
        count={count}
        size={size}
        setSize={setSize}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        direction={direction}
        setDirection={setDirection}
      />
    </PrimaryPage>
  );
};
export default Home;
