import React, { useEffect } from 'react';
import  createRoot  from "react-dom";
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import '@/Theme/app.css';
import App from '@/Components/App';
import store from '@/Redux/store';
import { NotifyProvider } from './Theme';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'auto',
          },
        },
      },
    },
  },
});

createRoot.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <NotifyProvider>
        <Router>
          <Routes>
            <Route path="/*" element={<App />}/>
          </Routes>
        </Router>
      </NotifyProvider>
    </ThemeProvider>
  </Provider>,
    document.getElementById('root')
  );

serviceWorker.unregister();
