/** PACKAGE IMPORTS */
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Redux as WebAuth } from '@ihdlab/web-auth';
import { useDispatch } from 'react-redux';

import Dashboard from '@/Components/Dashboard';
import Help from '@/Components/Help';
import Notifications from '@/Components/Notification';
import KitOrders from '@/Components/KitOrder';
import NewKitOrder from '@/Components/KitOrder/New';
import ViewKitOrder from '@/Components/KitOrder/View';
import Integtations from '@/Components/Partner/Integration';
import Kits from '@/Components/Kit';
import BatchKits from '@/Components/Kit/Batch';
import ViewKit from '@/Components/Kit/View';
import Patients from '@/Components/Patient';
import Customers from '@/Components/Customer';
import ViewCustomer from '@/Components/Customer/View';
import ViewPatient from '@/Components/Patient/View';
import LabOrders from '@/Components/LabOrder';
import NewLabOrder from '@/Components/LabOrder/New';
import PullLabOrder from '@/Components/LabOrder/Pull';
import ViewLabOrder from '@/Components/LabOrder/View';
import Partner from '@/Components/Partner';
import Profile from '@/Components/Profile';
import Shippings from '@/Components/Shipping';
import Admin from '@/Components/Admin';
import { Login } from '@/Components/Auth';
import { UserSvc, BaseSvc } from '@/Services';
import configs from '@/config';
import { ToastContainer } from 'react-toastify';

const Auth = WebAuth.Auth;
const Private = WebAuth.Private;
Auth.config(configs.auth);
Auth.printConfigs();
console.log('### REST_URI: ', configs.api.REST_URI);
const loadingUserData = async () => {
  console.log('### LOADING USER DATA...');
  try{
    await UserSvc.initSystem();
    await UserSvc.retriveMe();
  }catch (e){
    <Navigate to='/login' />;
  }
};

const App = () => {
  const dispatch = useDispatch();
  let authToken = Auth.getToken();
  let isLoggedIn = Auth.isLoggedIn();
  if (!isLoggedIn) {
    if (!authToken) {
      <Navigate to='/login' />;
    } else {
      dispatch(Auth.signIn(authToken));
    }
  }
  BaseSvc.config(authToken);
  if (isLoggedIn) {
    loadingUserData();
  }
  return (
    <>
      <ToastContainer position='bottom-right' />
      <Routes>
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/dashboard' element={<Private comp={Dashboard} />} />
        <Route exact path='/help' element={<Private comp={Help} />} />
        <Route
          exact
          path='/notification'
          element={<Private comp={Notifications} />}
        />
        <Route exact path='/kit/order' element={<Private comp={KitOrders} />} />
        <Route
          exact
          path='/kit/order/new'
          element={<Private comp={NewKitOrder} />}
        />
        <Route
          exact
          path='/kit/order/:id'
          element={<Private comp={ViewKitOrder} />}
        />
        <Route exact path='/patient' element={<Private comp={Patients} />} />
        <Route exact path='/lab' element={<Private comp={LabOrders} />} />
        <Route exact path='/lab/new' element={<Private comp={NewLabOrder} />} />
        <Route
          exact
          path='/lab/pull'
          element={<Private comp={PullLabOrder} />}
        />
        <Route
          exact
          path='/lab/:id'
          element={<Private comp={ViewLabOrder} />}
        />
        <Route exact path='/customer' element={<Private comp={Customers} />} />
        <Route
          exact
          path='/customer/:id'
          element={<Private comp={ViewCustomer} />}
        />
        <Route
          exact
          path='/patient/:id'
          element={<Private comp={ViewPatient} />}
        />
        <Route exact path='/kit' element={<Private comp={Kits} />} />
        <Route exact path='/kit/batch' element={<Private comp={BatchKits} />} />
        <Route exact path='/kit/:id' element={<Private comp={ViewKit} />} />
        <Route exact path='/profile' element={<Private comp={Profile} />} />
        <Route exact path='/shipping' element={<Private comp={Shippings} />} />
        <Route
          exact
          path='/partner/integration'
          element={<Private comp={Integtations} />}
        />
        <Route exact path='/partner' element={<Private comp={Partner} />} />
        <Route path='/admin/*' element={<Private comp={Admin} />} />
        <Route exact path='/' element={<Private comp={Dashboard} />} />
      </Routes>
    </>
  );
};
export default App;
