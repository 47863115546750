import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PersonIcon from '@mui/icons-material/Person';
import TestIcon from '@mui/icons-material/Bookmark';
import LabelsIcon from '@mui/icons-material/LocalShipping';
import OrdersIcon from '@mui/icons-material/ShoppingBasket';
import { DateTime } from 'luxon';
import {
  SecondaryPage,
  DataList,
  DataListItem,
  ContentCardContent,
  ContentCardHeader,
  ContentCardShadowed,
  PrimaryButton,
  FormButton,
  Loader,
} from '@/Theme';
import { Grid, Avatar, Typography, Divider, IconButton, Tooltip, Modal, Select, FormControl } from '@mui/material';
/** Local imports */
import Edit from './Edit';
import { OrderService } from '@/Services';
import { DropdownIcon, PrintIcon, TodoIcon, TodoCompletedIcon, InfoIcon, DownloadIcon, DeliveryIcon, ReturnIcon } from '@/Theme/Icons'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';

const _ = require('underscore')

const View = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusAnchor, setStatusAnchor] = useState(null);
  const taskMenuOpen = Boolean(anchorEl);
  const statusMenuOpen = Boolean(statusAnchor);
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState({
    shippingAddress: {},
    billingAddress: {},
  });
  const [customer, setCustomer] = useState({ address: {} });
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creatingLabels, setCreatingLabels] = useState(false);
  //for editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deliveryCarrier, setDeliveryCarrier] = useState("usps");
  const [deliveryService, setDeliveryService] = useState("Priority");
  const [returnCarrier, setReturnCarrier] = useState("usps");
  const [returnService, setReturnService] = useState("Priority");

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const refresh = () => {
    fetch();
  };
  const handleTaskMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStatusMenu = (event) => {
    setStatusAnchor(event.currentTarget);
  };
  const handleCloseTaskMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseStatusMenu = () => {
    setStatusAnchor(null);
  };
  const handleEdit = () => {
    setAction('edit');
    setOpenDrawer(true);
  };
  const handleDeliveryCarrierChange = (e) => {
    setDeliveryCarrier(e.target.value)
    switch (e.target.value) {
    case 'usps':
      setDeliveryService('Priority');
      break;
    case 'ups':
      setDeliveryService('3DaySelect');
      break;
    case 'fedex':
      setDeliveryService('FEDEX_EXPRESS_SAVER');
      break;
    default:
      break;
    }
  };
  const handleReturnCarrierChange = (e) => {
    setReturnCarrier(e.target.value)
    switch (e.target.value) {
    case 'usps':
      setReturnService('Priority');
      break;
    case 'ups':
      setReturnService('3DaySelect');
      break;
    case 'fedex':
      setReturnService('FEDEX_GROUND');
      break;
    default:
      break;
    }
  };
  const handleDeliveryServiceChange = (e) => {
    setDeliveryService(e.target.value)
  };
  const handleReturnServiceChange = (e) => {
    setReturnService(e.target.value)
  };

  const contentDrawer = () => {
    let drawerContent = '';
    if (openDrawer && action) {
      if (action === 'edit') {
        drawerContent = (
          <Edit
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedItem={selectedItem}
            refreshList={refresh}
          />
        );
      }
    }
    return drawerContent;
  };

  const fetch = async () => {
    setLoading(true);
    let response = await OrderService.getOrder(id);
    if (response && response.data) {
      setSelectedItem(response.data);
      setCustomer(response.data.customer);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, [id]);
  const handleRowClick = (lineItem) => {
    let link = `/kit/${lineItem.kit.id}`;
    navigate(link, { replace: true });
  };

  const columns = useMemo(
    () => [
      {
        name: 'Kit',
        sortable: true,
        cell: (row) => {
          return row.kit && row.kit.name;
        },
      },
      {
        name: 'Weight (oz)',
        selector: row => row.weight,
        sortable: true,
        cell: (row) => {
          return row.kit && row.kit.weight;
        },
      },
      {
        name: 'Quantity',
        selector: row => row.quantity,
        sortable: true,
        width: "100px"
      },
    ],
    []
  );
  const shippingColumns = useMemo(
    () => [
      {
        name: 'Tracking Number',
        sortable: true,
        cell: (row) => {
          return (
            <a href={row.shipping.trackingUrl} target="_blank" rel="noreferrer">
              {row.shipping.trackingNumber}
            </a>
          )
        },
      },
      {
        name: 'Type',
        selector: row => row.type,
        sortable: true,
        cell: (row) => {
          return row.type;
        },
      },
      {
        name: 'Created On',
        sortable: true,
        selector: row => row.created,
        cell: (row) => {
          return `${DateTime.fromISO(row.created).toLocaleString(
            DateTime.DATETIME_SHORT
          )}`;
        },
      },
      {
        name: 'Print/Download',
        sortable: false,
        width: "120px",
        cell: (row) => {
          return (
            <>
              <Tooltip title="Print Label">
                <IconButton onClick={() => OrderService.handlePrintLabel({ id: row.kitOrderId }, null, row.id)}>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download Label">
                <IconButton onClick={() => OrderService.handleDownloadLabel(row.shipping)}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </>
          )
        }
      },
    ],
    []
  );
  const title = () => {
    return (
      <>
        <OrdersIcon fontSize='xl' />
        <span style={{ marginLeft: '1rem' }}>
          {`Kit Order for ${customer.firstName} ${customer.lastName} 
          | ${customer.email} 
          | ${selectedItem.status}`}
        </span>
      </>
    );
  };
  const menuSx = { fontSize: '1.2rem', padding: '8px 3px' };
  const menuIconSx = { margin: '0 8px' };
  const handleUpdateStatus = async (status) => {
    let response = await OrderService.updateStatus(selectedItem, status);
    fetch();
  };
  const handleCreateLabel = async () => {
    setOpenModal(true);
  };
  const handleLabels = async () => {
    setOpenModal(false);
    setCreatingLabels(true);
    let data = { deliveryCarrier: deliveryCarrier, deliveryService: deliveryService, returnCarrier: returnCarrier, returnService: returnService }
    if (_.any(selectedItem.shippings, shipping => shipping.type === 'Delivery')) { data.deliveryCarrier = "None" }
    if (_.any(selectedItem.shippings, shipping => shipping.type === 'Return')) { data.returnCarrier = "None" }
    let response = await OrderService.createLabel(selectedItem.id, data);
    if (response.data && response.data.errors && response.data.errors.length > 0) {
      toast.error(response.data.errors[0])
    }
    setCreatingLabels(false);
    fetch();
  };
  return (
    <>
      <SecondaryPage title={title()} back='/kit/order' actions={
        <>
          {/*<PrimaryButton
            size='small'
            onClick={() => handleUpdateStatus()}
          >
            {selectedItem.status === 'Completed' ? <><TodoCompletedIcon sx={menuIconSx} /> Mark as Pending</> : <><TodoIcon sx={menuIconSx} /> Mark as Completed</>}
          </PrimaryButton>*/}
          <FormButton
            onClick={handleStatusMenu}
            primary="true"
            compact="true"
            endIcon={<DropdownIcon />}
          >
            Update Status
          </FormButton>
          <Menu
            id='menu-appbar'
            anchorEl={statusAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={statusMenuOpen}
            onClose={handleCloseStatusMenu}
          >
            {selectedItem.status !== 'Completed' && <MenuItem sx={menuSx} onClick={() => handleUpdateStatus('Completed')} style={{paddingRight:10}}>
              <TodoIcon sx={menuIconSx} /> Mark as Completed
            </MenuItem>}
            {selectedItem.status !== 'Pending' && <MenuItem sx={menuSx} onClick={() => handleUpdateStatus('Pending')} style={{paddingRight:10}}>
              <TodoCompletedIcon sx={menuIconSx} /> Mark as Pending
            </MenuItem>}
          </Menu>
        </>}>
        <ContentCardShadowed>
          <ContentCardHeader title={`Order Items ( ${selectedItem.status} )`} />
          <ContentCardContent>
            <DataTable
              className='pagination-custom'
              columns={columns}
              data={selectedItem.lineItems}
              noHeader
              onRowClicked={handleRowClick}
              highlightOnHover
              persistTableHead
              defaultSortField='name'
              defaultSortAsc={false}
            />
          </ContentCardContent>
        </ContentCardShadowed>
        <ContentCardShadowed group>
          <ContentCardHeader title="Shipping Tracking" action={
            <>
              {selectedItem.shippings && selectedItem.shippings.length > 0 && (
                <>
                  <FormButton
                    onClick={handleTaskMenu}
                    primary="true"
                    compact="true"
                    endIcon={<DropdownIcon />}
                  >
                    Labels
                  </FormButton>
                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={taskMenuOpen}
                    onClose={handleCloseTaskMenu}
                  >
                    <MenuItem
                      sx={menuSx}
                      onClick={() => OrderService.handlePrintLabel(selectedItem)}
                    >
                      <PrintIcon sx={menuIconSx} />
                      Print Labels
                    </MenuItem>
                    <MenuItem
                      sx={menuSx}
                      onClick={() => OrderService.handlePrintLabel(selectedItem, "Delivery")}
                    >
                      <DeliveryIcon sx={menuIconSx} />
                      Print Shipping Label
                    </MenuItem>
                    <MenuItem
                      sx={menuSx}
                      onClick={() => OrderService.handlePrintLabel(selectedItem, "Return")}
                    >
                      <ReturnIcon sx={menuIconSx} />
                      Print All Return Labels
                    </MenuItem>
                    <Divider style={{display:(_.filter(selectedItem.shippings, shipping => shipping.type === 'Delivery').length === 0 || _.filter(selectedItem.shippings, shipping => shipping.type === 'Return').length === 0 ? 'block' : 'none')}} />
                    <MenuItem
                      sx={menuSx}
                      onClick={handleCreateLabel}
                      style={{display:(_.filter(selectedItem.shippings, shipping => shipping.type === 'Delivery').length === 0 || _.filter(selectedItem.shippings, shipping => shipping.type === 'Return').length === 0 ? 'block' : 'none')}}
                    >
                      <LabelsIcon sx={menuIconSx} />
                      Create Labels
                    </MenuItem>
                  </Menu>
                </>)}</>} />
          <ContentCardContent>
            {(!selectedItem.shippings || selectedItem.shippings.length <= 0) && (
              <div className="flex-col-cc" style={{ padding: "2rem" }}>
                {!creatingLabels && <PrimaryButton
                  onClick={handleCreateLabel}
                  primary="true"
                  compact="true"
                >
                  Create Labels
                </PrimaryButton>
                }
                {creatingLabels && <Loader/>}
              </div>
            )}
            {selectedItem.shippings && selectedItem.shippings.length > 0 && (
              <DataTable
                className='pagination-custom'
                columns={shippingColumns}
                data={selectedItem.shippings}
                noHeader
                onRowClicked={handleRowClick}
                highlightOnHover
                persistTableHead
                defaultSortField='type'
                defaultSortAsc={false}
              />
            )}
          </ContentCardContent>
        </ContentCardShadowed>
        <ContentCardShadowed group>
          <ContentCardHeader
            title='Shipping Info'
            action={
              <FormButton primary compact onClick={handleEdit}>
                Edit
              </FormButton>
            }
          />
          <ContentCardContent>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <DataList>
                  <DataListItem
                    label='Name'
                    value={selectedItem.shippingAddress.name}
                  />
                  <DataListItem
                    label='Phone'
                    value={selectedItem.shippingAddress.phone || customer.home || customer.mobile}
                  />
                  <DataListItem
                    label='Email'
                    value={selectedItem.shippingAddress.email || customer.email}
                  />
                  <DataListItem
                    label='Shipping ID'
                    value={selectedItem.shippingAddress.epId ? "Yes - " + selectedItem.shippingAddress.epId : "No"}
                  />
                </DataList>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DataList>
                  <DataListItem
                    label="Address"
                    value={(selectedItem.shippingAddress.street1 + (selectedItem.shippingAddress.street2 || ''))}
                  />
                  <DataListItem
                    value={selectedItem.shippingAddress.city}
                  />
                  <DataListItem
                    value={selectedItem.shippingAddress.state + ", " + selectedItem.shippingAddress.postalCode}
                  />
                  <DataListItem
                    value={selectedItem.shippingAddress.country}
                  />
                </DataList>
              </Grid>
            </Grid>
          </ContentCardContent>
        </ContentCardShadowed>
        <ContentCardShadowed group>
          <ContentCardHeader title='Customer Info' />
          <ContentCardContent>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <DataList>
                  <DataListItem label='First Name' value={customer.firstName} />
                  <DataListItem label='Last Name' value={customer.lastName} />
                  <DataListItem label='Middle Name' value={customer.middleName} />
                  <DataListItem label='Email' value={customer.email} />
                  <DataListItem label='Primary Phone' value={customer.home} />
                  <DataListItem label='Mobile Phone' value={customer.mobile} />
                  <DataListItem label='External Id' value={customer.externalId} />
                  <DataListItem label='Metadata' value={customer.metadata} />
                </DataList>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DataList>
                  <DataListItem
                    label='Address'
                    value={customer.address.street1 + (customer.address.street2 ? customer.address.street2 : "")}
                  />
                  <DataListItem
                    value={customer.address.city}
                  />
                  <DataListItem
                    value={customer.address.state + ", " + customer.address.postalCode}
                  />
                  <DataListItem
                    value={customer.address.country}
                  />
                </DataList>
              </Grid>
            </Grid>
          </ContentCardContent>
        </ContentCardShadowed>
        <ContentCardShadowed group>
          <ContentCardHeader title='Billing Info' />
          <ContentCardContent>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <DataList>
                  <DataListItem
                    label='Name'
                    value={selectedItem.billingAddress.name}
                  />
                  <DataListItem
                    label='Phone'
                    value={selectedItem.billingAddress.phone || customer.home || customer.mobile}
                  />
                  <DataListItem
                    label='Email'
                    value={selectedItem.billingAddress.email || customer.email}
                  />
                </DataList>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DataList>
                  <DataListItem
                    label='Address'
                    value={selectedItem.billingAddress.street1 + (selectedItem.billingAddress.street2 ? selectedItem.billingAddress.street2 : "")}
                  />
                  <DataListItem
                    value={selectedItem.billingAddress.city}
                  />
                  <DataListItem
                    value={selectedItem.billingAddress.state + ", " + selectedItem.billingAddress.postalCode}
                  />
                  <DataListItem
                    value={selectedItem.billingAddress.country}
                  />
                </DataList>
              </Grid>
            </Grid>
          </ContentCardContent>
        </ContentCardShadowed>
        <ContentCardShadowed group>
          <ContentCardHeader title="Additional Info" />
          <ContentCardContent>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <DataList>
                  <DataListItem
                    label='Status'
                    value={
                      ((selectedItem.status))
                    }
                  />
                  <DataListItem
                    label='External ID'
                    value={
                      ((selectedItem.externalId || 'N/a'))
                    }
                  />
                </DataList>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DataList>
                  <DataListItem
                    label='Order Date'
                    value={
                      DateTime.fromISO(selectedItem.created).toLocaleString(
                        DateTime.DATETIME_SHORT
                      )
                    }
                  />
                  {_.find(selectedItem.shippings, shipping => shipping.type === 'Delivery') ? <DataListItem
                    label='Shipping Label Date'
                    value={
                      DateTime.fromISO(_.find(selectedItem.shippings, shipping => shipping.type === 'Delivery').created).toLocaleString(
                        DateTime.DATETIME_SHORT
                      )
                    }
                  /> : <DataListItem
                    label='Status Updated On'
                    value={
                      DateTime.fromISO(selectedItem.updated).toLocaleString(
                        DateTime.DATETIME_SHORT
                      )
                    }
                  />}
                </DataList>
              </Grid>
              {_.find(selectedItem.shippings, shipping => shipping.type === 'Delivery') && <Grid item xs={12} sm={6}>
                <DataList>
                  <DataListItem
                    label='Status Updated On'
                    value={
                      DateTime.fromISO(selectedItem.updated).toLocaleString(
                        DateTime.DATETIME_SHORT
                      )
                    }
                  />
                </DataList>
              </Grid>}
            </Grid>
          </ContentCardContent>
        </ContentCardShadowed>
        {contentDrawer()}
      </SecondaryPage>
      <Modal
        className='flex-col-cc'
        open={openModal}
        onBackdropClick={handleModal}
      >
        <div
          className='flex-col-cc guideModalPopup'
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          {_.filter(selectedItem.shippings, shipping => shipping.type === 'Delivery').length === 0 && <div style={{display: 'flex',flexFlow: 'row',marginBottom:20}}>
            <h2 style={{marginTop:8,marginRight:20}}>Delivery:</h2>
            <FormControl
              variant='standard'
              sx={{ minWidth: 120, marginRight: '20px' }}
            >
              <Select
                labelId='shipping-carrier'
                id='shipping-carrier'
                value={deliveryCarrier}
                label='Carrier'
                onChange={handleDeliveryCarrierChange}
                sx={{ fontSize: 16 }}
              >
                <MenuItem value='usps'>
                  USPS
                </MenuItem>
                <MenuItem value='fedex'>
                  Fedex
                </MenuItem>
                <MenuItem value='ups'>
                  UPS
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant='standard'
              sx={{ minWidth: 120, marginRight: '20px' }}
            >
              <Select
                labelId='shipping-service'
                id='shipping-service'
                value={deliveryService}
                label='Service'
                onChange={handleDeliveryServiceChange}
                sx={{ fontSize: 16 }}
              >
                {deliveryCarrier === 'usps' && [
                  <MenuItem value='Priority' key='Priority'>
                    Priority
                  </MenuItem>,
                  <MenuItem value='First' key='First'>
                    First Class
                  </MenuItem>
                ]}
                {deliveryCarrier === 'fedex' && [
                  <MenuItem value='FEDEX_EXPRESS_SAVER' key='FEDEX_EXPRESS_SAVER'>
                    Express
                  </MenuItem>,
                  <MenuItem value='FEDEX_2_DAY' key='FEDEX_2_DAY'>
                    2nd Day Air
                  </MenuItem>,
                  <MenuItem value='FEDEX_2_DAY_AM' key='FEDEX_2_DAY_AM'>
                    2nd Day Air - AM
                  </MenuItem>,
                  <MenuItem value='PRIORITY_OVERNIGHT' key='PRIORITY_OVERNIGHT'>
                    Priority Overnight
                  </MenuItem>,
                  <MenuItem value='STANDARD_OVERNIGHT' key='STANDARD_OVERNIGHT'>
                    Standard Overnight
                  </MenuItem>
                ]}
                {deliveryCarrier === 'ups' && [
                  <MenuItem value='3DaySelect' key='3DaySelect'>
                    3 Day Select
                  </MenuItem>,
                  <MenuItem value='2ndDayAir' key='2ndDayAir'>
                    2nd Day Air
                  </MenuItem>,
                  <MenuItem value='NextDayAirSaver' key='NextDayAirSaver'>
                    Next Day Air
                  </MenuItem>
                ]}
              </Select>
            </FormControl>
          </div>}
          {_.filter(selectedItem.shippings, shipping => shipping.type === 'Return').length === 0 && <div style={{display: 'flex',flexFlow: 'row',marginBottom:20}}>
            <h2 style={{marginTop:8,marginRight:20}}>Return:</h2>
            <FormControl
              variant='standard'
              sx={{ minWidth: 120, marginRight: '20px' }}
            >
              <Select
                labelId='shipping-carrier'
                id='shipping-carrier'
                value={returnCarrier}
                label='Carrier'
                onChange={handleReturnCarrierChange}
                sx={{ fontSize: 16 }}
              >
                <MenuItem value='usps'>
                  USPS
                </MenuItem>
                <MenuItem value='fedex'>
                  Fedex
                </MenuItem>
                <MenuItem value='ups'>
                  UPS
                </MenuItem>
                <MenuItem value='none'>
                  None
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant='standard'
              sx={{ minWidth: 120, marginRight: '20px' }}
            >
              <Select
                labelId='shipping-service'
                id='shipping-service'
                value={returnService}
                label='Service'
                onChange={handleReturnServiceChange}
                sx={{ fontSize: 16 }}
              >
                {returnCarrier === 'usps' && [
                  <MenuItem value='Priority' key='Priority'>
                    Priority
                  </MenuItem>,
                  <MenuItem value='First' key='First'>
                    First Class
                  </MenuItem>
                ]}
                {returnCarrier === 'fedex' && [
                  <MenuItem value='FEDEX_GROUND' key='FEDEX_GROUND'>
                    Ground
                  </MenuItem>,
                  <MenuItem value='FEDEX_2_DAY' key='FEDEX_2_DAY'>
                    2nd Day Air
                  </MenuItem>,
                  <MenuItem value='FEDEX_2_DAY_AM' key='FEDEX_2_DAY_AM'>
                    2nd Day Air - AM
                  </MenuItem>,
                  <MenuItem value='PRIORITY_OVERNIGHT' key='PRIORITY_OVERNIGHT'>
                    Priority Overnight
                  </MenuItem>,
                  <MenuItem value='STANDARD_OVERNIGHT' key='STANDARD_OVERNIGHT'>
                    Standard Overnight
                  </MenuItem>
                ]}
                {returnCarrier === 'ups' && [
                  <MenuItem value='3DaySelect' key='3DaySelect'>
                    3 Day Select
                  </MenuItem>,
                  <MenuItem value='2ndDayAir' key='2ndDayAir'>
                    2nd Day Air
                  </MenuItem>,
                  <MenuItem value='NextDayAirSaver' key='NextDayAirSaver'>
                    Next Day Air
                  </MenuItem>
                ]}
              </Select>
            </FormControl>
          </div>}
          <div style={{display: 'flex',flexFlow: 'row'}}>
            <PrimaryButton
              onClick={handleLabels}
              primary="true"
              compact="true"
            >
              Create Labels
            </PrimaryButton>
            {/*_.filter(selectedItem.shippings, shipping => shipping.type === 'Delivery').length === 0 && <PrimaryButton
              onClick={handleOutgoingLabel}
              primary="true"
              compact="true"
            >
              Create Outgoing Label
            </PrimaryButton>}
            {_.filter(selectedItem.shippings, shipping => shipping.type === 'Return').length === 0 && <PrimaryButton
              onClick={handleReturnLabel}
              primary="true"
              compact="true"
            >
              Create Return Labels
            </PrimaryButton>*/}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default View;
