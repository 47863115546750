import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import SettingsInputIcon from '@mui/icons-material/TuneOutlined';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const SelectFilterField = styled(Select)`
  && {
    .MuiSelect-select {
      padding: 2px 3px 2px 8px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #0277bd;
    }
    margin-left: 8px;
    margin-right: 8px;
  }
`;
/**
 * TextField
 */
const FormTextField = styled(
  ({ height, large, compact, width, readOnly = false, ...otherProps }) => (
    <TextField
      variant='standard'
      {...otherProps}
      InputProps={{ disableUnderline: true, readOnly: readOnly }}
      InputLabelProps={{ shrink: true }}
    />
  )
)`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  && {
    .MuiFormHelperText-root {
      font-size: 1rem;
      line-height: 1.2rem;
    }
    .MuiFormLabel-root {
      font-size: 14px;
      font-size: 1.4rem;
      color: #282828;
      padding-left: 3px;
    }
    .Mui-error {
      color: #ff0000;
    }
    .MuiInputBase-input {
      background: #f6f6f6;
      border-radius: 5px;
      border: ${(props) =>
        !props.error ? '1px solid #DDDDDD' : '1px solid #ff0000'};
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 2.8rem;
      padding: 0 8px;
      height: auto;
      margin: 5px 0 8px 0;
      ${(props) =>
        props.compact &&
        `
	        min-wdith: 0;
	        font-size: 10px;
	        font-size: 1rem;
	        line-height:1.8rem;
        `}
      ${(props) =>
        props.large &&
        `
            font-size: 16px;
            font-size: 1.6rem;
            font-weight: 400;
            line-height:3.8rem;
        `}
    }
    .MuiInputBase-input:focus,
    .MuiInputBase-input:hover {
      -webkit-box-shadow: inset 0px 0px 9px 1px rgba(225, 225, 255, 0.36);
      box-shadow: inset 0px 0px 9px 1px rgba(225, 225, 255, 0.36);
      border-color: rgba(225, 225, 255, 1);
      background: #ffffff;
      border: 1px solid #f6f6f6;
    }
    .Mui-focused .MuiInputBase-input {
      background: #ffffff;
      border: 1px solid #f6f6f6;
    }
    .MuiInputBase-input::placeholder {
      font-size: 11px;
      font-size: 1.1rem;
      font-style: italic;
      padding-left: 0.3rem;
    }
    .MuiSelect-root.MuiInputBase-input {
      // padding: 0 8px;
      // margin: 5px 0 8px 0;
    }
    .MuiSelect-icon {
      top: calc(50% - 10px);
      right: 3px;
    }
  }
`;
const InputField = ({
  label,
  field,
  value,
  onChange,
  props,
  ...otherProps
}) => {
  const { handleChange, values, errors, touched, required } = props;
  value = value || values[field] || '';
  return (
    <FormTextField
      margin='dense'
      name={field}
      key={`key-${field}`}
      label={touched[field] && errors[field] ? errors[field] : label}
      value={value}
      required={required}
      placeholder={otherProps.placeholder || `Enter ${label.toLowerCase()}`}
      {...otherProps}
      error={touched[field] && Boolean(errors[field])}
      onChange={onChange || handleChange}
    />
  );
};
const FormButton = styled(
  ({ primary, large, compact, edge, fill, ...otherProps }) => (
    <Button {...otherProps} />
  )
)`
	&&{
		color: #0277bd;
		background:none;
		padding: .5rem 1.1rem .4rem;
		font-size: 1.3rem;
		border:1px solid;
		border-color:transparent;
		margin:0 .5rem;
		:first-child{
			margin-left:0;
		}
		:last-child{
			margin-right:0;
		}
		&:hover {
			color:#EF599D;
			background: #FFFFFF;
			border: 1px solid rgba(239, 89, 157, 0.36);
	
		};
		
		${(props) =>
      props.primary &&
      `
			border-radius: .3rem;
			color: #FFFFFF;
			background:#2a2277;
			border: 1px solid #0277bd;
			&:hover {
				color:#EF599D;
				background:none;
				background: #FFFFFF;
				border: 1px solid rgba(239, 89, 157, 0.36);
				-webkit-box-shadow: inset 0px 0px 9px 1px rgba(239, 89, 157, 0.36);
				box-shadow: inset 0px 0px 9px 1px rgba(239, 89, 157, 0.36);
			
			}
		`}	
    ${(props) => (props.edge ? `margin: ${props.edge};` : ``)}
		${(props) => (props.fill ? `background: ${props.fill};` : ``)}
		.MuiButton-label{
			align-items: end;
		}
		${(props) =>
      props.compact &&
      `
			line-height:1.5rem;
			min-wdith: 0;
			padding: .4rem .9rem .2rem;
			font-size: 1.2rem;
		`}	
		${(props) =>
      props.large &&
      `
			padding: .5rem 1.3rem;
			font-size: 15px;
			font-size: 1.5rem;
			font-weight: 400;
		`}	
		${(props) =>
      props.compact &&
      `
			.MuiButton-startIcon > *:first-child {
				font-size: 14px;
				font-size: 1.4rem;
			}
		`}	
`;
const FormMenuItem = styled(MenuItem)`
  && {
    font-size: 1.4rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    position: 'relative',
    borderRadius: '20px',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
      border: '1px solid #EF599D',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    padding: '2px 10px',
    border: '1px solid #0277bd',
  },
  searchIcon: {
    width: theme.spacing(2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0277bd',
  },
  inputRoot: {
    color: 'inherit',
    marginLeft: '2rem',
  },
  inputInput: {
    padding: theme.spacing(0.5, 0.5, 0.5, 3),
    transition: theme.transitions.create('width'),
    width: '100%',
    fontSize: '1.3rem',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));
const SearchBox = ({ value, onChange, onPress, placeholder }) => {
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon fontSize='large' />
      </div>
      <InputBase
        value={value}
        placeholder={placeholder || 'Search…'}
        onChange={onChange && ((e) => onChange(e))}
        onKeyPress={onPress && ((e) => onPress(e))}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': placeholder || 'search' }}
      />
    </div>
  );
};
const FilterBox = ({
  value,
  onChange,
  onPress,
  placeholder,
  filter,
  extend,
}) => {
  return (
    <>
      <SearchBox
        value={value}
        onChange={onChange}
        onPress={onPress}
        placeholder={placeholder}
      />
      {extend && <>{extend}</>}
      {filter && (
        <FormButton
          color='primary'
          compact
          startIcon={<SettingsInputIcon />}
          onClick={() => filter()}
        >
          Filter
        </FormButton>
      )}
    </>
  );
};
////// END FORM COMPONENTS
export {
  FormTextField,
  InputField,
  FormButton,
  FormMenuItem,
  SearchBox,
  FilterBox,
  SelectFilterField,
};
